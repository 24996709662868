import { Vue, Component } from "vue-property-decorator";
import * as validations from "@/utils/validations";

interface Rules {
  [key: string]: Rule;
}

export type Rule = (input: string) => string | true;

@Component
export default class RulesMixin extends Vue {
  get rules(): Rules {
    return {
      required: (input: string) =>
        validations.required(input, this.$tc("field.error.required")),
      email: (input: string) =>
        validations.email(input, this.$tc("field.error.email")),
      url: (input: string) =>
        validations.url(input, this.$tc("field.error.url")),
      phone: (input: string) =>
        validations.phone(input, this.$tc("field.error.phone")),
      number: (input: string) =>
        validations.number(input, this.$tc("field.error.number")),
      percentage: (input: string) =>
        validations.percentage(input, this.$tc("field.error.percentage")),
    };
  }
}
