import { Vue, Component, Prop } from "vue-property-decorator";

type CallbackFunction = (...args: any) => Promise<any>;

@Component
export default class BaseForm<T> extends Vue {
  @Prop({ default: null }) entityId!: number;

  data: T | null = null;
  isLoading = false;
  isSaving = false;
  wasSaved = false;
  isValid = false;

  get isEditing(): boolean {
    return !!this.entityId;
  }

  async handleFormSubmit(
    saveFunction: CallbackFunction,
    error?: string
  ): Promise<void> {
    try {
      this.isSaving = true;
      const response = await saveFunction();
      this.wasSaved = true;
      this.$emit("success", response);
    } catch (e) {
      let message = error || "apiErrors.unableToSave";
      if (this.$te(`apiErrors.${e.response?.data?.message}`)) {
        message = this.$t(`apiErrors.${e.response?.data?.message}`).toString();
      }

      this.$snackbarError(message);
      this.$emit("fail");
    } finally {
      this.isSaving = false;
    }
  }

  async handleFormLoad(
    saveFunction: CallbackFunction,
    error?: string
  ): Promise<void> {
    try {
      this.isLoading = true;
      await saveFunction();
    } catch (e) {
      this.$snackbarError(this.$tc(error || "apiErrors.unableToLoad"));
    } finally {
      this.isLoading = false;
    }
  }
}
