














import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DialogWrapper extends Vue {
  @Prop({ default: false, type: Boolean }) value!: boolean;
  @Prop({ default: true, type: Boolean }) eager!: boolean;
  @Prop({ default: "750", type: [String, Number] }) maxWidth!: string;
  @Prop({ default: false, type: Boolean }) fullscreen!: boolean;

  closeDialog(): void {
    this.$emit("close");
  }

  get showFullscreen(): boolean {
    return this.$vuetify.breakpoint.xsOnly || this.fullscreen;
  }
}
