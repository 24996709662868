





















































































































import { Vue, Component } from "vue-property-decorator";
import WebsiteApiService from "@/services/WebsiteApiService";
import { getFirstQueryValue } from "@/utils/queryParams";
import { formatPrice } from "@/utils/string";
import Link from "@/components/common/Link.vue";
import BTFile from "@/entity/File";
import {
  ExpressionOfInterestFormQuestion,
  ExpressionOfInterestFormQuestionType,
} from "@/entity/ExpressionOfInterest";
import ExpressionOfInterestFiles from "@/components/common/ExpressionOfInterestFiles.vue";

enum Status {
  LOADING = "LOADING",
  READY = "READY",
  INVALID_TOKEN = "INVALID_TOKEN",
}

@Component({
  components: {
    Link,
    ExpressionOfInterestFiles,
  },
})
export default class ExpressionOfInterestPreview extends Vue {
  currentStatus = Status.LOADING;

  questions: ExpressionOfInterestFormQuestion[] = [];
  answers: Record<number, string> = {};
  attachedFiles: BTFile[] | null = null;

  get token() {
    return getFirstQueryValue(this.$route.query.token);
  }

  get showSkeleton() {
    return this.currentStatus === Status.LOADING;
  }

  get showTokenInvalid() {
    return this.currentStatus === Status.INVALID_TOKEN;
  }

  canShowAsLink(value: null | string): boolean {
    return value !== null && value.length > 0;
  }

  isQuestionUrl(questionTitle: string, questionTitle2: string) {
    return (
      questionTitle.toUpperCase().includes("URL") ||
      questionTitle2.toUpperCase().includes("URL")
    );
  }

  async fetchEoiV2() {
    if (this.token === undefined) {
      this.currentStatus = Status.INVALID_TOKEN;
      return;
    }

    const resV2 = await WebsiteApiService.getBuyersExpressionOfInterestByToken(
      this.token
    );
    this.questions = resV2.questions.sort((a, b) => a.orderNum - b.orderNum);
    this.attachedFiles = resV2.file;
    if (resV2.answers) {
      this.answers = resV2.answers.reduce(
        (acc, item) => ({ ...acc, [item.questionId]: item.answer }),
        {}
      );
    } else {
      this.answers = resV2.questions.reduce(
        (acc, item) => ({ ...acc, [item.id]: "" }),
        {}
      );
    }
    this.currentStatus = Status.READY;
  }

  isQuestionTypeAmount(questionType: ExpressionOfInterestFormQuestionType) {
    return questionType === ExpressionOfInterestFormQuestionType.AMOUNT;
  }

  formatAmount(amount: string | number) {
    return formatPrice(amount);
  }

  created() {
    this.fetchEoiV2();
  }
}
