var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-main',{attrs:{"id":"outside-main"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.$t('expressionOfInterest'))}})],1),_c('v-card-text',{staticClass:"pb-4 pt-8 px-12"},[_c('v-layout',{staticClass:"mb-4",attrs:{"justify-end":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-img',{attrs:{"src":require("@/../public/logo.png"),"alt":"logo","height":"67","width":"263"}})],1)],1),_c('v-skeleton-loader',{attrs:{"type":"image","transition":"scale-transition","loading":_vm.showSkeleton}},[_c('div',[(!_vm.showTokenInvalid)?_c('div',[_c('div',{staticClass:"\n                        mb-4\n                        font-weight-medium\n                        grey--text\n                        text--darken-3\n                      "},[_c('h2',{staticClass:"font-weight-bold blue--text text--darken-4"},[_vm._v(" "+_vm._s(_vm.$t("interested"))+" ")])]),_vm._l((_vm.questions),function(question){return _c('div',{key:question.id},[(question.primaryText)?_c('h5',{staticClass:"\n                          font-weight-bold\n                          blue--text\n                          text--darken-4 text-h6\n                          mb-2\n                        ",domProps:{"textContent":_vm._s(question.primaryText)}}):_vm._e(),_c('p',{staticClass:"\n                          text--primary\n                          mb-1\n                          font-weight-bold\n                          grey--text\n                          text--darken-3\n                        ",domProps:{"textContent":_vm._s(question.secondaryText)}}),(
                          _vm.isQuestionUrl(
                            question.primaryText,
                            question.secondaryText
                          )
                        )?_c('Link',{staticClass:"mb-4 d-inline-block",attrs:{"href":_vm.answers[question.id],"no-prefix":""}}):(
                          _vm.isQuestionTypeAmount(question.questionTypeId)
                        )?_c('p',{staticClass:"text--secondary",domProps:{"textContent":_vm._s(_vm.formatAmount(_vm.answers[question.id]))}}):_c('p',{staticClass:"text--secondary",domProps:{"textContent":_vm._s(_vm.answers[question.id])}})],1)}),(_vm.attachedFiles)?_c('div',[_c('h5',{staticClass:"\n                          font-weight-bold\n                          blue--text\n                          text--darken-4 text-h6\n                          mb-2\n                        ",domProps:{"textContent":_vm._s('Attached Files')}}),(_vm.attachedFiles)?_c('ExpressionOfInterestFiles',{attrs:{"files":_vm.attachedFiles}}):_vm._e()],1):_vm._e()],2):_c('p',[_vm._v(" "+_vm._s(_vm.$t("tokenIsNotValid"))+" ")])])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }