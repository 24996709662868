import { TranslateResult } from "vue-i18n";

/*
There methods are supossed to be used as "rules" for vuetify components
Expected responses are true or false/ string but since always have translated error code we only need true or string
String means that there is an error
 */

export const email = (
  input: string,
  errorMsg: string | TranslateResult
): true | string => {
  if (input === "") {
    return true;
  }

  const regexp = /^[^\s@]+@[^\s@]+\.[A-Za-z1-9]+$/;
  return regexp.test(input) ? true : errorMsg.toString();
};

export const required = (
  input: string | any[] | undefined | null,
  errorMsg: string | TranslateResult
): true | string => {
  if (input === undefined || input === null) {
    return errorMsg.toString();
  }

  return input.length !== 0 ? true : errorMsg.toString();
};

export const phone = (
  input: string,
  errorMsg: string | TranslateResult
): true | string => {
  if (input === "" || input === undefined || input === null) {
    return true;
  }
  // eslint-disable-next-line no-useless-escape
  const regexp = /^[\d\+\s\(\)]+$/;
  return regexp.test(input) ? true : errorMsg.toString();
};

export const number = (
  input: string | null,
  errorMsg: string | TranslateResult
): true | string => {
  if (input === null) {
    return errorMsg.toString();
  }

  const regexp = /^(\d*)$/;
  const noCurrFormatting = input.toString().replace(/'/g, "");
  return regexp.test(noCurrFormatting) ? true : errorMsg.toString();
};

export const percentage = (
  input: string | number | null,
  errorMsg: string | TranslateResult
): true | string => {
  if (input === null) {
    return errorMsg.toString();
  }

  const asNum = typeof input === "string" ? parseInt(input) : input;

  if (Number.isNaN(asNum)) {
    return errorMsg.toString();
  }

  if (asNum < 0 || asNum > 100) {
    return errorMsg.toString();
  }

  return true;
};

export const decimalNumber = (
  input: string,
  errorMsg: string | TranslateResult
): true | string => {
  const regexp = /^(\d*).(\d*)$/;
  return regexp.test(input.toString()) ? true : errorMsg.toString();
};

export const url = (input: string, errorMsg: string | TranslateResult) => {
  if (input === "") {
    return true;
  }

  const regexp =
    /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
  return regexp.test(input) ? true : errorMsg.toString();
};
