







































import { Vue, Component, Prop } from "vue-property-decorator";
import FormActionButtons from "@/components/form/FormActionButtons.vue";

@Component({
  components: {
    FormActionButtons,
  },
})
export default class FormCard extends Vue {
  @Prop({ default: "", type: String }) title!: string;
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;
  @Prop({ default: false, type: Boolean }) isSaving!: boolean;
  @Prop({ required: false, type: Boolean }) isDeleting!: boolean;
  @Prop({ default: false, type: Boolean }) isValid!: boolean;
  @Prop({ default: 1, type: Number }) activePage!: number;
  @Prop({ default: 1, type: Number }) numberOfSteps!: number;
  @Prop({ default: true, type: Boolean }) capitalizeTitle!: boolean;
  @Prop({ default: false, type: Boolean }) noEdit!: boolean;
}
