import { Vue, Component } from "vue-property-decorator";
import DialogWrapper from "@/components/common/DialogWrapper.vue";

@Component({
  components: {
    DialogWrapper,
  },
})
export default class DialogMixin extends Vue {
  activeDialog: string | null = null;
  dialogEntityId: number | string | null = null;

  openDialog(dialogType: string, entityId?: number | string | null): void {
    this.activeDialog = dialogType;
    this.dialogEntityId = entityId || null;
  }

  closeDialog(): void {
    this.activeDialog = null;
    this.dialogEntityId = null;
    this.activeDialog = null;
  }

  get isDialogOpened(): boolean {
    return this.activeDialog !== null;
  }
}
