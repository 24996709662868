





























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FormActionButtons extends Vue {
  @Prop({ required: true, type: Boolean }) isValid!: boolean;
  @Prop({ required: true, type: Boolean }) isSaving!: boolean;
  @Prop({ required: true, type: Boolean }) isDeleting!: boolean;
  @Prop({ required: true, type: Number }) activePage!: number;
  @Prop({ required: true, type: Number }) numberOfSteps!: number;
  @Prop({ required: false, type: Boolean }) noEdit!: number;

  get isAtLastPage(): boolean {
    return this.activePage === this.numberOfSteps;
  }

  get isAtFirstPage(): boolean {
    return this.activePage === 1;
  }
}
